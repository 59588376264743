import SpecialPages from '@/elements/Layouts/SpecialPages';
import { getCsrfToken } from 'next-auth/react';

import { getCookie } from '@/lib/getCookie';
import LoginForm from '@/components/LoginForm';

const SignIn = ({ session, csrfToken }) => (
  <SpecialPages bottom title={'Login'}>
    <LoginForm session={session} csrfToken={csrfToken} />
  </SpecialPages>
);

export default SignIn;

export async function getServerSideProps({ req, res }) {
  const token = await getCsrfToken({ req });
  return {
    props: {
      cookies: getCookie(req.headers.cookie ?? ''),
      csrfToken: token,
    },
  };
}
