import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loader, SquareAsterisk } from 'lucide-react';
import { signIn } from 'next-auth/react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Alert, AlertDescription, AlertTitle } from '@/elements/ui/alert';
import { Button } from '@/elements/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/elements/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/elements/ui/form';
import { Input } from '@/elements/ui/input';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});
const LoginForm = ({ csrfToken }) => {
  const [hasError, setError] = useState(false);
  const router = useRouter();
  const form = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: { email: '', password: '', csrfToken },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const isLoading = false;

  const onSubmit = async (values, { setSubmitting }) => {
    setError(false);
    const res = await signIn('credentials', { redirect: false, ...values });
    if (!!res.error) {
      setError(true);
    } else {
      await router.push('/');
    }
  };

  return (
    <Card className={'mx-auto w-full max-w-md bg-white bg-opacity-95'}>
      <CardHeader>
        <CardTitle>my.milkmonkey - Login</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
            {hasError ? (
              <Alert variant={'destructive'}>
                <SquareAsterisk className="h-4 w-4" />
                <AlertTitle>Zugang verweigert</AlertTitle>
                <AlertDescription>
                  Die Zugangsdaten sind nicht korrekt.
                </AlertDescription>
              </Alert>
            ) : null}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      onePIgnore={false}
                      placeholder="e-Mail"
                      autoCapitalize="none"
                      autoComplete="email"
                      autoCorrect="off"
                      size={'large'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      onePIgnore={false}
                      autoCapitalize="none"
                      autoCorrect="off"
                      size={'large'}
                      placeholder="Passwort"
                      type={'password'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={isLoading} size={'lg'} className={'w-full'}>
              {isLoading && <Loader className="mr-2 h-4 w-4 animate-spin" />}
              einloggen
            </Button>
            <div className="mt-4 grid justify-end gap-1">
              <Link href={'/pw-reset'}>Passwort Vergessen</Link>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
