import React from 'react';

const SpecialPages = ({ children, bottom, title = 'dashboard' }) => {
  return (
    <main className={`special-page${bottom ? ' special-page--bottom' : ''}`}>
      <aside className={'special-page__aside'} data-mm-title={title} />
      <div className={'special-page__content'}>{children}</div>
      <footer className="special-page__footer">
        <h6>milkmonkey</h6>
        <nav className={'special-page__nav'}>
          <ul className="special-page__nav-list">
            <li className="special-page__nav-item">
              <a href="https://www.milkmonkey.de/impressum">impressum</a>
            </li>
            <li className="special-page__nav-item">
              <a href="https://www.milkmonkey.de/datenschutz">datenschutz</a>
            </li>
          </ul>
        </nav>
      </footer>
    </main>
  );
};

export default SpecialPages;
