import * as React from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const inputVariants = cva(
  'flex w-full rounded-md border border-input bg-background ring-offset-background file:border-0 file:bg-transparent placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        small: ['text-sm py-1 px-2'],
        default: ['text-sm py-2 px-3 file:text-sm file:font-medium'],
        large: ['text-lg py-3 px-4 file:text-lg file:font-medium'],
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

const Input = React.forwardRef(
  ({ className, type, size, onePIgnore = true, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ size, className }), className)}
        ref={ref}
        data-1p-ignore={onePIgnore}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
